.guide-list {
    list-style: none;
    margin: em(58px) 0 0;
    padding: 0;

    @media only screen and (min-width: em(820px, 16px)) {
        @include flexbox;
        @include justify-content(center);
    }
}

.guide-list__guide {
    background-color: #fff;
    border: 1px solid #eceaea;
    border-radius: 3px;
    box-sizing: border-box;
    margin: 0 0 em(32px);
    padding: em(44px) em(20px) em(100px);
    position: relative;

    @media only screen and (min-width: em(820px, 16px)) {
        margin-left: em(20px);
        margin-right: em(20px);
        max-width: em(360px);
    }
}

.guide-list__icon {
    margin: 0 0 em(26px);
}

.guide-list__title {
    font-size: 32px;
    font-weight: 400;
    color: #494a4b;
    line-height: 37px;
    margin: 0 0 20px;
}

.guide-list__description {
    font-size: 18px;
    color: #9d9fa0;
    line-height: 24px;
    margin: 0;
}

.guide-list__link {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}

.guide-list__link span {
    bottom: 45px;
    color: #60cab0;
    font-size: 20px;
    font-weight: 600;
    left: 50%;
    line-height: em(24px, 20px);
    position: absolute;
    @include transform(translateX(-50%));
}

.guide-list__link span::after {
    content: " ›";
}

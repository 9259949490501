@font-face {
    font-family: 'Proxima Nova';
    src: url($font-path + 'proxima-nova.eot');
    src: url($font-path + 'proxima-nova.eot?#iefix') format('embedded-opentype'),
        url($font-path + 'proxima-nova.woff') format('woff'),
        url($font-path + 'proxima-nova.ttf') format('truetype'),
        url($font-path + 'proxima-nova.svg#proxima-_nova_rgregular') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url($font-path + 'proxima-nova-semibold.eot');
    src: url($font-path + 'proxima-nova-semibold.eot?#iefix') format('embedded-opentype'),
        url($font-path + 'proxima-nova-semibold.woff') format('woff'),
        url($font-path + 'proxima-nova-semibold.ttf') format('truetype'),
        url($font-path + 'proxima-nova-semibold.svg#proxima-_novasemibold') format('svg');
    font-weight: 600;
    font-style: normal;
}

html {
    font-family: $base-font-stack;
}

h1,
h2,
h3 {
    font-family: $heading-font-stack;
}

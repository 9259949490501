.templates-promo {
    @media only screen and (min-width: em(925px, 16px)) {
        background: url('/assets/tdk.png') no-repeat;
        background-size: 220px auto;
        background-position: 100px center;
    }

    @media only screen and (min-width: em(1010px, 16px)) {
        background-size: 310px auto;
    }

    @media only screen and (min-width: em(1100px, 16px)) {
        background-size: 380px auto;
    }

    @media only screen and (min-width: em(1160px, 16px)) {
        background-size: auto;
        background-position: left bottom;
    }
}

.resource-layout__content {
    font-size: 18px;
    line-height: em(27px, 18px);
    padding-bottom: em(60px);
    width: 100%;
    @include font-smoothing;
    @include style-content;

    @media only screen and (min-width: em(800px, 16px)) {
        font-size: 20px;
        line-height: em(30px, 20px);
        margin: em(118px, 20px) 0 0;
    }
}

.resource-layout__content table {
    border-bottom: 2px solid #f8f8f8;
    border-top: 2px solid #f8f8f8;

    @media only screen and (min-width: em(900px, 16px)) {
        table-layout: fixed;
    }
}

.resource-layout__content tr {
    border-bottom: 1px solid #f8f8f8;
}

.resource-layout__content tr:first-child {
    border-top: none;
}

.resource-layout__content tr:last-child {
    border-bottom: none;
}

.resource-layout__content td {
    display: block;
    font-size: 18px;
    vertical-align: top;

    @media only screen and (min-width: em(900px, 16px)) {
        display: table-cell;
        padding-bottom: 16px;
        padding-top: 16px;
    }
}

.resource-layout__content td:first-child {
    @media only screen and (min-width: em(900px, 16px)) {
        padding-right: 40px;
        text-align: right;
        width: 260px;
    }
}

.resource-layout__content td:only-child {
    @media only screen and (min-width: em(900px, 16px)) {
        padding-right: 500px;
        text-align: right;
        width: 260px;
        @include calc(padding-right, '100% - 260px');
    }
}

.resource-layout__content td:first-child strong {
    display: inline-block;
    font-weight: 600;
    word-break: break-all;
}

.resource-layout__content td:first-child span {
    color: #7e848e;
    font-size: 16px;

    @media only screen and (min-width: em(900px, 16px)) {
        display: block;
    }
}

.page-layout__content {
    font-size: 18px;
    line-height: em(27px, 18px);
    padding-bottom: em(60px);
    width: 100%;
    @include font-smoothing;
    @include style-content;

    @media only screen and (min-width: em(800px, 16px)) {
        font-size: 20px;
        line-height: em(30px, 20px);
        margin: em(118px, 20px) 0 0;
    }
}

img.img-border {
    border: 1px #CCC solid;
}
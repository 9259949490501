.blog {
    @media only screen and (min-width: em(880px, 16px)) {
        display: flex;
    }
}

.blog__sidebar {
    margin: em(36px) 0 0;

    @media only screen and (min-width: em(880px, 16px)) {
        margin: em(118px) em(40px) em(142px) 0;
        width: 13.79%;
    }
}

.blog__sidebar-title {
    border-bottom: 1px solid #f3f3f4;
    color: #2F3437;
    font-size: 18px;
    font-weight: 600;
    line-height: em(21px, 18px);
    margin: em(32px) 0 0 0;
    padding: 0 0 em(6px);
    text-transform: uppercase;
}

.blog__content {
    margin: em(40px) 0 em(64px);

    @media only screen and (min-width: em(880px, 16px)) {
        margin: em(118px) 0 em(142px);
        max-width: em(760px);
        width: 79.1675%;
    }
}

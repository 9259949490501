.grid-list {
    list-style: none;
    margin: em(65px) auto 0;
    overflow: auto;
    padding: 0 em(20px);

    @media only screen and (min-width: em(800px, 16px)) {
        max-width: em(960px);
        padding: 0 em(100px);
    }
}

.grid-list__item {
    box-sizing: border-box;
    display: block;
    float: left;
    margin: 0 0 em(45px);
    text-align: left;

    @media only screen and (min-width: em(765px, 16px)) {
        padding: 0 2.5% 0 0;
        width: 50%;
    }

    @media only screen and (min-width: em(1160px, 16px)) {
        margin: 0 em(90px) em(60px) 0;
        padding: 0;
        width: em(260px);
    }
}

.grid-list__item:nth-child(2n) {
    @media only screen and (min-width: em(765px, 16px)) {
        padding: 0 0 0 2.5%;
    }

    @media only screen and (min-width: em(980px, 16px)) {
        padding: 0;
    }
}

.grid-list__item:nth-child(3n) {
    margin-right: 0;
}

.grid-list__item-title {
    font-size: em(24px);
    margin: 0 0 em(10px);
}

.grid-list__item-label {
    display: inline-block;
    vertical-align: middle;
}

.grid-list__item-copy {
    line-height: em(24px, 18px);
}

.grid-list__item-copy,
.grid-list__item-link {
    font-size: em(18px);
    margin: 0 0 em(12px);
}

.grid-list__item-link {
    font-weight: 600;
    color: #60cab0;
}

table {
    margin-bottom: em(30px);
    width: 100%;
}

tr {
    display: block;
    border-bottom: em(1px) solid lighten($primary-text-color, 35%);
    position: relative;

    &:first-child {
        border-top: em(1px) solid lighten($primary-text-color, 35%);
    }

    @media only screen and (min-width: em(620px, 16px)) {
        display: table-row;
        position: static;

        &:first-child {
            border-top: 0;
        }
    }
}

thead {
    position: absolute;
    left: -9999px;
    top: -9999px;

    @media only screen and (min-width: em(620px, 16px)) {
        position: static;
        left: auto;
        top: auto;
    }
}

th,
td {
    padding: em(10px) em(20px) em(10px) 0;
    text-align: left;

    &:last-child {
        padding-right: 0;
    }
}

th {
    color: #424242;
    font-family: $base-font-stack;
    font-weight: 600;
}

td {
    display: block;

    @media only screen and (min-width: em(620px, 16px)) {
        display: table-cell;
    }
}

td:nth-child(2):nth-last-child(2) {
    @media only screen and (min-width: em(400px, 16px)) and (max-width: em(619px, 16px)) {
        padding-right: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.code-list {
    list-style: none;
    margin: em(20px) 0 0;
    padding: 0;
    @include clearfix;
    @include flexbox;
    @include flex-wrap(wrap);
    @include justify-content(space-between);

    @media only screen and (min-width: em(900px, 16px)) {
        margin: 0 auto;
        max-width: 1160px;
    }
}

.code-list__item {
    display: block;
    margin: em(20px) 0;
    min-width: 110px;
    width: 50%;

    @media only screen and (min-width: em(500px, 16px)) {
        float: left;
    }

    @media only screen and (min-width: em(900px, 16px)) {
        float: none;
        margin: em(40px) 0 0;
        width: em(180px);
    }
}

%code-list__item-link {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100px 64px;
    display: block;
    padding: 80px 0 8px;
}

%code-list__item-link h3 {
    margin: 0;
}

.code-list__item-link--php {
    @extend %code-list__item-link;
    background-image: url('/assets/php-logo.svg');
}

.code-list__item-link--ruby {
    @extend %code-list__item-link;
    background-image: url('/assets/ruby-logo.svg');
}

.code-list__item-link--net {
    @extend %code-list__item-link;
    background-image: url('/assets/net-logo.svg');
}

.code-list__item-link--python {
    @extend %code-list__item-link;
    background-image: url('/assets/python-logo.svg');
}

.bookshelf {
    list-style: none;
    margin: em(60px) 0 0;
    padding: 0 em(20px);
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    @include font-smoothing;

    @media only screen and (min-width: em(800px, 16px)) {
        @include flex-direction(row);
    }
}

.bookshelf__item {
    border: 1px solid #dfdcdc;
    box-sizing: border-box;
    display: block;
    margin: 0 0 em(18px);
    padding: em(45px) em(15px) em(68px);
    position: relative;
    text-align: center;

    @media only screen and (min-width: em(800px, 16px)) {
        margin-bottom: 0;
        margin-right: em(10px);
        width: em(330px);
    }

    @media only screen and (min-width: em(1000px, 16px)) {
        margin-right: em(70px);
    }
}

.bookshelf__item:last-child {
    margin-right: 0;
}

.bookshelf__item-icon {
    margin: 0 0 em(26px);
}

.bookshelf__item-title {
    font-size: 28px;
    font-weight: 400;
    color: #2f3437;
    line-height: em(33px, 28px);
    margin: 0 0 em(24px, 28px);
}

.bookshelf__item-copy {
    font-size: 18px;
    font-weight: 400;
    color: #8D9193;
    line-height: em(24px, 18px);
    margin: 0 0 em(24px, 18px);
}

.bookshelf__item-link {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.bookshelf__item-link span {
    bottom: 45px;
    color: #60cab0;
    font-size: 20px;
    font-weight: 600;
    left: 50%;
    line-height: em(24px, 20px);
    position: absolute;
    @include transform(translateX(-50%));
}

.bookshelf__item-link span::after {
    content: " ›";
}

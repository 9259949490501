.post-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.post-list__post {
    margin: 0 0 em(65px);
}

.post-list__post:last-child {
    margin-bottom: 0;
}

.post-list__post-link {
    color: inherit;
    text-decoration: none;
}

.post-list__post-date {
    text-decoration: none;
}

.post-list__post-read {
    display: inline-block;
}

.post-list__post-read a {
    border: 1px solid #dfdcdc;
    border-radius: em(30px);
    color: inherit;
    font-size: 18px;
    padding: em(8px, 18px) em(14px, 18px) em(7px, 18px) em(18px, 18px);
    text-decoration: none;
}

.post-list__post-read a::after {
    content: " →";
    font-family: "Lucida Grande", sans-serif;
}

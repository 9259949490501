$input-transition-duration: 0.25s;
$input-transition-timing-function: ease-out;

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

label[for] {
    cursor: pointer;
}

input {
    border-width: em($input-border-width);
    border-style: solid;
    box-shadow: $input-box-shadow;
    border-radius: em($input-border-radius);
    padding: em(6px) em(8px);
    @include box-sizing(border-box);
    @include transition(
        color $input-transition-duration $input-transition-timing-function,
        border-color $input-transition-duration $input-transition-timing-function
    );
    &:focus {
        outline: none;
    }
}

input[type="number"] {
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button {
        display: none;
    }
}

@each $placeholder-vendor in (webkit, ms) {
    ::-#{$placeholder-vendor}-input-placeholder {
        color: $input-placeholder-color;
    }
}

// Firefox quite inexplicably renders this lighter than WebKit,
// thus use a darker grey to compensate.
:-moz-placeholder,
::-moz-placeholder {
    color: $input-placeholder-color;
    opacity: 1;
}

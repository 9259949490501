.post {
    margin: em(40px) 0 em(64px);
    @include clearfix;
    @include font-smoothing;

    @media only screen and (min-width: em(880px, 16px)) {
        margin: 0;
    }
}

.post__sidebar {
    margin: 0 0 em(32px);

    @media only screen and (min-width: em(880px, 16px)) {
        padding: em(36px) 0 0;
        position: absolute;
    }
}

.post__back-link {
    margin: 0;

    @media only screen and (min-width: em(880px, 16px)) {
        margin: em(40px) 0 0;
    }
}

.post__back-link a {
    border-radius: em(30px);
    border: 1px solid #dfdcdc;
    color: inherit;
    font-size: 18px;
    padding: em(8px, 18px) em(18px, 18px) em(7px, 18px) em(14px, 18px);
    text-decoration: none;
}

.post__back-link a::before {
    content: "← ";
    font-family: "Lucida Grande", sans-serif;
}

.post__datetime {
    color: #989898;
    display: block;
    font-size: 14px;
    line-height: em(16px, 14px);
    margin: 0 0 em(12px, 14px);
    text-transform: uppercase;
}

.post__title {
    font-size: 34px;
    font-weight: 600;
    line-height: em(48px, 42px);
    margin-top: 0;

    @media only screen and (min-width: em(765px, 16px)) {
        font-size: 42px;
    }
}

.post__body {
    font-size: 18px;
    line-height: em(27px, 18px);

    @media only screen and (min-width: em(765px, 16px)) {
        font-size: 20px;
        line-height: em(30px, 20px);
    }
}

.post__body {
    @include style-content();
}

.post__tags {
    border-top: 1px solid #f3f3f4;
    margin: em(36px) 0 0;
    padding: em(16px) 0 0;
}

.post__tag-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.post__tag {
    background-color: #f3f3f4;
    border-radius: em(28px);
    display: inline-block;
    padding: em(6px) em(16px) em(5px);
    margin: 0 0 0 em(10px);
}

.post__tag:first-child {
    margin-left: 0;
}

.post__tag-text {
    color: $primary-text-color;
    font-size: 16px;
    line-height: em(17px, 16px);
}

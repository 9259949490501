.info-box {
    background-color: #f8f8f8;
    box-sizing: border-box;
    margin: 64px auto 0;
    max-width: 560px;
    padding: 32px 20px;
    text-align: center;
}

.info-box__separator {
    background-color: #f3f3f4;
    border: 0;
    height: 1px;
    margin: 36px 0 0;
    width: 100%;
}

.info-box__title {
    font-size: 24px;
    font-weight: 400;
    color: #494a4b;
    line-height: em(28px, 24px);
    margin: 0 0 em(16px, 24px);
}

.info-box__copy {
    font-size: 18px;
    font-weight: 400;
    color: #656972;
    line-height: em(26px, 18px);
    margin: 0 0 em(14px, 18px);
}

.info-box__copy:last-child {
    margin-bottom: 0;
}

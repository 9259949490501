@-o-viewport {
    width: device-width;
    zoom: 1;
}

@-ms-viewport {
    width: device-width;
    zoom: 1;
}

[hidden] {
    display: none !important;
}

.container {
    box-sizing: border-box;
    max-width: $base-max-content-width;
    margin: 0 auto;
    overflow: auto;
    padding-left: em(20px);
    padding-right: em(20px);
}

%visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    &:focus,
    &:active {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
    }
}


.subheader {
    background: #3c3c3c;
    padding: em(40px) 0 em(34px);
}

.subheader__heading-part {
    color: #fff;
    display: inline-block;
    font-size: em(38px);
    margin: 0;
    padding: 0 em(15px, 38px) 0 0;

    @include font-smoothing;
}

.subheader__heading-part--muted {
    @extend .subheader__heading-part;

    color: #828282;
    display: block;
    font-family: $base-font-stack;
    font-size: em(20px);
    margin-top: em(8px, 20px);

    @media only screen and (min-width: em(475px, 16px)) {
        display: inline-block;
        margin-top: 0;

        &::before {
            content: "/";
            padding-right: em(15px);
        }
    }
}

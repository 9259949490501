%header {
    background-color: $header-background-color;
}

.header {
    @extend %header;
}

.header--embeded {
    @extend %header;
    background-color: transparent;
}

.header__contents {
    margin: 0 auto;

    @media only screen and (min-width: em(765px, 16px)) {
        box-sizing: border-box;
        height: em(85px);
        max-width: em(1200px);
        padding: 0 em(20px);
        @include flexbox;
        @include align-items(center);
        @include justify-content(space-between);
    }
}

.header__brand-link {
    display: block;
    line-height: 0;
    margin: 0 0 0 em(20px);
    text-align: left;
    text-decoration: none;

    @media only screen and (min-width: em(765px, 16px)) {
        margin: 0;
    }
}

.header__brand-image {
    height: em(24px);
    margin: em(16px) 0 em(14px);
    width: em(99px);

    @media only screen and (min-width: em(765px, 16px)) {
        margin: 0;
    }

    @media only screen and (min-width: em(830px, 16px)) {
        height: em(33px);
        width: em(136px);
    }
}

.header__navigation {
    background-color: darken($header-background-color, 2.5%);
    overflow-x: auto;
    position: relative;
    width: 100%;
    -webkit-overflow-scrolling: touch;

    @media only screen and (min-width: em(765px, 16px)) {
        background-color: transparent;
        overflow-x: visible;
        width: auto;
    }
}

.header__navigation-list {
    display: table-cell;
    list-style: none;
    margin: 0;
    padding: 0 em(10px);
    position: relative;

    @media only screen and (min-width: em(765px, 16px)) {
        display: block;
        padding: 0;
    }
}

.header__navigation-item {
    display: table-cell;
    padding: 0 em(6px) 0 0;
    position: relative;

    @media only screen and (min-width: em(765px, 16px)) {
        display: inline-block;
        margin-right: em(26px);
        padding: 0;
    }
}

.header__navigation-item:last-child {
    padding-right: 0;

    @media only screen and (min-width: em(765px, 16px)) {
        margin-right: 0;
    }
}

.header__navigation-item-link {
    color: #dfdcdc;
    display: block;
    padding: em(9px) em(10px);
    text-decoration: none;
    white-space: nowrap;

    @media only screen and (min-width: em(765px, 16px)) {
        padding-top: em(14px);
        padding-bottom: em(14px);
    }
}

.header__navigation-item-label {
    font-size: em(14px);
    font-weight: 600;
    text-transform: uppercase;
    @include font-smoothing;
}

code {
    color: #9e4446;
    font-size: em(13px);
    padding: em(2px, 13px) em(6px, 13px);
    background-color: #f8f8f8;
    border-radius: em(3px, 13px);
    line-height: em(24px, 13px);
    word-wrap: break-word;
}

code,
pre {
    font-family: Consolas,
                 Menlo,
                 Monaco,
                 "Andale Mono WT",
                 "Andale Mono",
                 "Lucida Console",
                 "Lucida Sans Typewriter",
                 "DejaVu Sans Mono",
                 "Bitstream Vera Sans Mono",
                 "Liberation Mono",
                 "Nimbus Mono L",
                 "Courier New",
                 Courier,
                 monospace;
}

code[class*="language"],
pre[class*="language"] {
    font-size: 15px;
    line-height: 22px;
    white-space: pre;
    word-break: normal;
    word-wrap: normal;

    @media only screen and (min-width: em(800px, 16px)) {
        white-space: pre-wrap;
        @include word-break;
    }
}

pre[class*="language"] {
    -webkit-overflow-scrolling: touch;
}

pre > code {
    background-color: transparent;
    border: 0;
    padding: 0;
}

code.language-html {
    display: block;
    overflow-x: auto;
    padding: 1em;
    -webkit-overflow-scrolling: touch;
}

.sidebar {
    display: inline-block;
    float: left;
    margin: em(32px) 0 0;
    overflow-y: auto;
    width: 100%;

    @include font-smoothing;

    @media only screen and (min-width: em(800px, 16px)) {
        left: 20px;
        margin-top: em(118px);
        margin-bottom: 0;
        margin-right: 7%;
        position: absolute;
        width: em(200px);
    }

    @media only screen and (min-width: em(1200px, 16px)) {
        @include calc(left, '(100vw - 1160px) / 2');
    }
}

.sidebar.is-fixed {
    @media only screen and (min-width: em(800px, 16px)) {
        bottom: 0;
        position: fixed;
        top: em(24px);
        margin: 0;
    }
}

.sidebar__title {
    font-size: 20px;
    font-weight: 600;
    color: #494a4b;
    line-height: em(24px, 20px);
    margin: 0 0 em(12px, 20px);
}

.sidebar__list {
    list-style: none;
    margin: 0 0 em(36px);
    padding: em(8px) 0 0 em(20px);
    position: relative;
}

.sidebar__list::before {
    background-color: #d8d8d8;
    bottom: em(18px);
    content: "";
    display: block;
    left: em(5px);
    position: absolute;
    top: 0;
    width: em(2px);
}

.sidebar__item-link {
    color: #494a4b;
    display: block;
    font-size: 18px;
    line-height: em(21px, 18px);
    padding: em(9px, 18px) 0;
    position: relative;
}

.sidebar__item-link.is-active::before {
    content: "";
    background-color: #60cab0;
    border: 4px solid #fff;
    border-radius: 100%;
    box-sizing: border-box;
    display: block;
    height: em(14px, 18px);
    left: em(-21px, 18px);
    position: absolute;
    top: 50%;
    width: em(14px, 18px);
    @include transform(translateY(-50%));
}

.sidebar + .content {
    display: inline-block;
    float: left;

    @media only screen and (min-width: em(800px, 16px)) {
        margin-left: em(260px, 20px);
        @include calc(width, '100% - 260px');
    }

    @media only screen and (min-width: em(1060px, 16px)) {
        max-width: em(760px, 20px);
        width: 79.1675%;
    }
}

/*

Name:       Base16 Ocean Dark
Author:     Chris Kempson (http://chriskempson.com)

Prism template by Bram de Haan (http://atelierbram.github.io/syntax-highlighting/prism/)
Original Base16 color scheme by Chris Kempson (https://github.com/chriskempson/base16)

 */ 

code[class*="language-"],
pre[class*="language-"] {
  color: #eff1f5;
  font-family: Consolas, Menlo, Monaco, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", "Courier New", Courier, monospace;
  font-size: 14px;
  line-height: 1.375;
  direction: ltr;
  text-align: left;
  word-spacing: normal;

  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;

  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none; 
  white-space: pre; 
  white-space: pre-wrap; 
  word-break: break-all;
  word-wrap: break-word; 
  background: #2b303b; 
  color: #c0c5ce;
}

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  margin: .5em 0;
  overflow: auto;   
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: .1em;
  border-radius: .3em;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #65737e;
}

.token.punctuation {
  color: #c0c5ce;
}

.namespace {
  opacity: .7;
}

.token.null,
.token.operator,
.token.boolean,
.token.number {
  color: #d08770;
} 
.token.property { 
  color: #ebcb8b;
}
.token.tag { 
  color: #8fa1b3;
} 
.token.string {
  color: #96b5b4;
} 
.token.selector { 
  color: #b48ead;
}
.token.attr-name { 
  color: #d08770;
} 
.token.entity,
.token.url, 
.language-css .token.string,
.style .token.string {
  color: #96b5b4;
}

.token.attr-value,
.token.keyword,
.token.control,
.token.directive,
.token.unit {
  color: #a3be8c;
} 

.token.statement,
.token.regex, 
.token.atrule { 
  color: #96b5b4;
}

.token.placeholder,
.token.variable {
  color: #8fa1b3;
} 

.token.important {
  color: #bf616a;
  font-weight: bold;
} 

.token.entity {
  cursor: help;
} 

pre > code.highlight {
  outline: .4em solid red;
  outline-offset: .4em;
} 

.selection-grid {
    margin: em(60px) auto 0;
    max-width: em(1000px);

    @include font-smoothing;
}

.selection-grid__list {
    list-style: none;
    padding: 0;
    margin: 0;

    @include clearfix;
}

.selection-grid__item {
    border-bottom: solid 1px #4d4d4d;
    float: left;
    height: em(190px);
    position: relative;
    width: 50%;

    @include box-sizing(border-box);
}

.selection-grid__item:nth-child(odd) {
    border-right: solid 1px #4d4d4d;
}

.selection-grid__item:last-child,
.selection-grid__item:nth-last-child(2) {
    border-bottom: 0;
}

@media only screen and (min-width: em(760px, 16px)) {
    .selection-grid__item {
        border-right: solid 1px #4d4d4d;
        width: 33.33%;
    }

    .selection-grid__item:nth-child(3n) {
        border-right: 0;
    }
}

.selection-grid__item-link {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-decoration: none;
    top: 0;
}

.selection-grid__item-link:focus {
    outline: none;
}

.selection-grid__item-icon {
    height: em(48px);
    left: 50%;
    position: absolute;
    top: em(45px);
    width: em(48px);
    @include transform(translateX(-50%));
}

.selection-grid__item-icon-layer {
    fill: #808080;
    @include transition(fill .65s);

    .selection-grid__item-link:focus,
    .selection-grid__item-link:hover & {
        fill: #fff;
    }
}

.selection-grid__item-icon-layer--muted {
    fill: #636363;
}

.selection-grid__item-label {
    color: #808080;
    font-family: $heading-font-stack;
    font-size: em(20px);
    left: 50%;
    position: absolute;
    top: em(123px, 20px);
    width: 100%;
    @include transition(color .65s);
    @include transform(translateX(-50%));

    .selection-grid__item-link:focus,
    .selection-grid__item-link:hover & {
        color: #fff;
    }
}

.selection-grid__item-label--muted {
    @extend .selection-grid__item-label;
    color: #636363;
}

// Fonts

$font-path: '../fonts/';
$base-font-stack: 'Proxima Nova', 'Avenir Next', Helvetica Neue, Arial, sans-serif;
$heading-font-stack: $base-font-stack;

// Typography

$base-font-size: 16px;
$heading-font-weight: 400;

// Color

$primary-text-color: #494a4b;
$secondary-text-color: #2f3437;

$base-background-color: #fff;

$header-background-color: #2f3437;

$h1-text-color: $secondary-text-color;
$h2-text-color: $secondary-text-color;
$h3-text-color: $secondary-text-color;
$h4-text-color: #686868;
$h5-text-color: $secondary-text-color;
$h6-text-color: $secondary-text-color;

$link-color: blue;
$link-underline-color: #f3f3f4;

$input-text-color: $primary-text-color;
$input-background-color: #fff;
$input-border-color: #f0f0f2;
$input-focus-text-color: $primary-text-color;
$input-focus-background-color: #fff;
$input-focus-border-color: #a0a4ab;
$input-placeholder-color: $secondary-text-color;

// Layout

$input-border-width: 1px;
$input-box-shadow: none;
$input-border-radius: 1px;

$base-max-content-width: 1200px;

@mixin style-content() {
    a {
        border-bottom: 2px solid $link-underline-color;
    }

    a > code {
        border-bottom: 2px solid transparent;
    }

    h1 {
        font-weight: 600;
    }

    h1 {
        margin-top: 0;
    }

    h2 {
        &:first-child {
            margin-top: 0;
        }
    }

    p + h2,
    p + h3,
    p + h4,
    p + h5,
    p + h6,
    ol + h2,
    ol + h3,
    ol + h4,
    ol + h5,
    ol + h6,
    ul + h2,
    ul + h3,
    ul + h4,
    ul + h5,
    ul + h6,
    pre + h2,
    pre + h3,
    pre + h4,
    pre + h5,
    pre + h6,
    table + h2,
    table + h3,
    table + h4,
    table + h5,
    table + h6 {
        margin-top: em(24px);
    }

    img {
        max-width: 100%;
    }

    li {
        margin-bottom: em(10px, 18px);
    }

    .section-title + p > code {
        background-color: transparent;
        border: 0;
        color: #424242;
        display: block;
        font-size: 20px;
        margin-top: em(-4px, 20px);
        padding: 0;

        @include word-break;
    }

    .highlight {
        border-radius: em(2px);
        margin-bottom: em(35px);
    }
}

.footer {
    background-color: #fff;
    padding: em(30px) 0 em(29px);
}

.footer__contents {
    margin: 0 auto;
    max-width: em(1200px);
    padding: 0 em(20px);
    position: relative;
    text-align: center;
}

.footer__brand {
    height: em(26px);
    margin: 0 0 em(16px);
    width: em(32px);

    @media only screen and (min-width: em(1100px, 16px)) {
        margin: em(6px) 0 0;
    }
}

.footer__copyright {
    color: #8d9193;
    font-size: 14px;
    line-height: em(19px, 14px);
    margin: 0;

    @media only screen and (min-width: em(1100px, 16px)) {
        left: em(20px, 14px);
        padding: em(11px, 14px) 0 0;
        position: absolute;
        top: 0;
    }
}

.footer__navigation {
    list-style: none;
    margin: 0 0 em(12px);
    padding: 0;

    @media only screen and (min-width: em(1100px, 16px)) {
        position: absolute;
        right: em(20px);
        top: 0;
    }
}

.footer__navigation-item {
    display: inline-block;
}

.footer__navigation-item-link {
    color: #8d9193;
    display: block;
    font-size: 14px;
    font-weight: 600;
    padding: em(12px, 14px);
    text-decoration: none;
    text-transform: uppercase;
}

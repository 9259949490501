%label {
    border-radius: 3px;
    font-size: 12px;
    letter-spacing: 0.6px;
    line-height: 12px;
    text-transform: uppercase;
}

.label--warning {
    @extend %label;
    background-color: #ec5171;
    color: #fff;
    font-weight: 600;
    padding: 4.5px 8px 4px;
}

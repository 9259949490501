.button {
    color: #fff;
    background-color: #60cab0;
    border-radius: 3px;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, .2);
    display: inline-block;
    font-family: $base-font-stack;
    font-size: 18px;
    font-weight: 600;
    padding: em(15px, 18px) em(40px, 18px) em(14px, 18px);
    text-decoration: none;
    text-shadow: 0 1px 1px #3ba189;
    text-transform: uppercase;

    @include font-smoothing;
    @include linear-gradient(#60cab0, #47b99d);
    @include transition(background-color .15s, box-shadow .225s);
}

.button:active {
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, .2), inset 0px 4px 3px #389a82;
}

%column-section {
    border-top: 1px solid #e1e1e1;
    margin: 0 auto;
    padding: em(68px) 0 0;

    @media only screen and (min-width: em(800px, 16px)) {
        max-width: em(960px);
        padding: em(68px) em(100px) 0;
    }

    @media only screen and (min-width: em(1160px, 16px)) {
        padding: em(68px) 0 0;
    }
}

.column-section--right {
    @extend %column-section;
    text-align: right;
}

.column-section__column {
    display: inline-block;
    margin: 0 0 em(54px);
    padding: 0 20px;
    text-align: left;

    @media only screen and (min-width: em(800px, 16px)) {
        padding: 0;
    }

    @media only screen and (min-width: em(925px, 16px)) {
        max-width: em(460px);
    }
}

.column-section__title {
    color: #2F3437;
    font-size: 36px;
    font-weight: 400;
    line-height: em(42px, 36px);
    margin: 0 0 em(24px, 36px);
}

.column-section__copy {
    color: #8d9193;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin: 0 0 20px;

    &:last-child {
        margin-bottom: 0;
    }

    @media only screen and (min-width: em(785px, 16px)) {
        font-size: 20px;
        line-height: 30px;
    }
}

.column-section__link {
   font-size: em(20px);
   font-weight: 600;
   color: #60CAB0;
   line-height: em(28px, 20px);
}

.section-title a {
    color: #2F3437;
    display: block;
    font-size: 42px;
    font-weight: 600;
    line-height: em(51px, 42px);
    margin-top: em(80px, 42px);
    position: relative;
    text-decoration: none;

    @include font-smoothing;
}

.section-title:first-child a {
    margin-top: 0;
}

html {
    text-rendering: optimizeLegibility;
    @media only screen and (min-width: em(525px, 16px)) {
        font-size: $base-font-size;
    }
}

h1,
h2,
h3 {
    font-weight: $heading-font-weight;
}

h4,
h5,
h6 {
    font-weight: 600;

    @include font-smoothing;
}

a {
    text-decoration: none;
}

@each $placeholder-vendor in (webkit, moz, ms) {
    ::-#{$placeholder-vendor}-input-placeholder {
        font-weight: 300;
    }
}


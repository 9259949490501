html {
    color: $primary-text-color;
    background: $base-background-color;
}

h1 {
    color: $h1-text-color;
}

h2 {
    color: $h2-text-color;
}

h3 {
    color: $h3-text-color;
}

h4 {
    color: $h4-text-color;
}

h5 {
    color: $h5-text-color;
}

h6 {
    color: $h6-text-color;
}

a {
    color: $link-color;
}

input {
    color: $input-text-color;
    background: $input-background-color;
    border-color: $input-border-color;
    &:focus {
        color: $input-focus-text-color;
        background: $input-focus-background-color;
        border-color: $input-focus-border-color;
    }
}

input[type=submit],
input[type=reset],
input[type=button],
input[type=file],
button {
    color: $primary-text-color;
    &:focus {
        color: $primary-text-color;
    }
}

@include input-placeholder() {
    color: $input-placeholder-color;
}

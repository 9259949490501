@mixin input-placeholder() {
    ::-webkit-input-placeholder {
        @content;
    }

    :-moz-placeholder {
        @content;
        opacity: 1;
    }

    ::-moz-placeholder {
        @content;
        opacity: 1;
    }

    :-ms-input-placeholder {
        @content;
    }
}

%promo-section {
    background-color: #fff;
    padding: em(60px) 0;
    @include font-smoothing;

    @media only screen and (min-width: em(785px, 16px)) {
        padding-bottom: em(85px);
        padding-top: em(85px);
    }
}

.promo-section {
    @extend %promo-section;
}

.promo-section--centered {
    @extend %promo-section;
}

.promo-section--bottomless {
    @extend %promo-section;
    padding-bottom: 0;
}

.promo-section--cut-out {
    @extend %promo-section;
    background-color: rgba(47, 51, 55, .85);
}

.promo-section--accented {
    @extend %promo-section;
    background-color: #f8f8f8;
    padding: em(80px) 0;
}

%promo-section__content {
    padding: 0 em(20px);
    text-align: center;

    @media only screen and (min-width: em(1160px, 16px)) {
        margin: 0 auto;
        max-width: 52.7%;
        padding: 0;
    }
}

.promo-section__content {
    @extend %promo-section__content;
}

.promo-section__content--full {
    @extend %promo-section__content;

    @media only screen and (min-width: em(1160px, 16px)) {
        max-width: 100%;
    }
}

.promo-section__columns {
    overflow: auto;
    padding: 0 em(20px);

    @media only screen and (min-width: em(800px, 16px)) {
        padding: 0 em(100px);
    }

    @media only screen and (min-width: em(1160px, 16px)) {
        margin: 0 auto;
        max-width: em(960px);
        padding: 0;
    }
}

.promo-section__column {
    float: left;
    margin: 0 0 em(36px);
    text-align: left;

    @media only screen and (min-width: em(1160px, 16px)) {
        margin: 0;
        width: em(460px);
    }
}

.promo-section__column:last-child {
    margin-bottom: 0;
}

.promo-section--accented .promo-section__column {
    @media only screen and (min-width: em(1160px, 16px)) {
        width: em(428px);
    }
}

.promo-section__column:first-child {
    @media only screen and (min-width: em(1160px, 16px)) {
        margin-right: em(40px);
    }
}

.promo-section--accented .promo-section__column:first-child {
    @media only screen and (min-width: em(1160px, 16px)) {
        margin-right: em(104px);
    }
}

.promo-section__column-title {
    font-size: 28px;
    color: #2F3437;
    line-height: 33px;
    margin: 0 0 28px;
}

.promo-section--accented .promo-section__column-title {
    font-size: 24px;
    margin-bottom: 20px;
}

%promo-section__title {
    color: #2F3437;
    font-size: 36px;
    font-weight: 400;
    line-height: 42px;
    margin: 0 0 32px;
    text-align: center;

    @media only screen and (min-width: em(785px, 16px)) {
        font-size: 42px;
        line-height: 49px;
    }
}

.promo-section__title {
    @extend %promo-section__title;
}

.promo-section__title--smaller {
    @extend %promo-section__title;
    font-size: 32px;

    @media only screen and (min-width: em(785px, 16px)) {
        font-size: 36px;
    }
}

.promo-section__title--inverse {
    @extend %promo-section__title;
    color: #fff;
}

%promo-section__subtitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    color: #8d9193;
    margin: -14px 0 0;
    text-align: center;

    @media only screen and (min-width: em(785px, 16px)) {
        font-size: 20px;
        line-height: 30px;
    }
}

.promo-section__content--full %promo-section__subtitle {
    @media only screen and (min-width: em(1160px, 16px)) {
        margin-left: auto;
        margin-right: auto;
        max-width: 52.7%;
    }
}

.promo-section__subtitle {
    @extend %promo-section__subtitle;
}

%promo-section__copy {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin: 0 0 36px;

    @media only screen and (min-width: em(785px, 16px)) {
        font-size: 20px;
        line-height: 30px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.promo-section__copy {
    @extend %promo-section__copy;
    color: #8d9193;
}

.promo-section__copy--inverse {
    @extend %promo-section__copy;
    color: #fff;
}

.promo-section--accented .promo-section__copy {
    font-size: 18px;
}

.promo-section--centered .promo-section__copy {
    margin-left: auto;
    margin-right: auto;
    max-width: em(780px, 20px);
}

%alert {
    border-radius: 3px;
    margin: 0 0 em(32px);
    padding: em(20px);
}

%alert :first-child {
    margin-top: 0;
}

%alert :last-child {
    margin-bottom: 0;
}

%alert a {
    color: inherit;
}

.alert--warning {
    @extend %alert;
    background-color: #ec5171;
    color: #fff;
}
